import React from 'react';
import { Button, Typography } from 'antd';
import AuthLayout from 'components/Layout/AuthLayout';
import { Router } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';
import useLocalizedPath from 'hooks/useLocalizedPath';
import QueueAnim from 'rc-queue-anim';
import PrivateRoute from './PrivateRoute';

const { Title, Paragraph } = Typography;

function AuthClient() {
  const { t } = useI18next();

  const handleOpenClientApp = () => {
    window.location.replace('moppium-client://');
  };

  return (
    <AuthLayout>
      <QueueAnim
        className="auth-banner-content-wrapper"
        delay={300}
        ease="easeOutQuart"
      >
        <Title level={3}>{t('moppiumClient')}</Title>
        <Paragraph>{t('linkedYourAccountToMoppiumClient')}</Paragraph>
        <Button type="primary" size="large" onClick={handleOpenClientApp}>
          {t('openClientApp')}
        </Button>
      </QueueAnim>
    </AuthLayout>
  );
}

const PrivateAuthClient = () => {
  const localizedPath = useLocalizedPath('auth/client');

  return (
    <Router basepath={localizedPath}>
      <PrivateRoute path="/" component={AuthClient} />
    </Router>
  );
};

export default PrivateAuthClient;
